<template>
  <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
    <individial_requests />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Required services',
components: {
individial_requests: defineAsyncComponent( () => import('@/components/Profile/IndividialRequests.vue') ),
},
}
</script>
